<template>
  <v-card>
    <v-form v-model="formValid">
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-textarea
              rows="2"
              v-model.trim="form.text"
              label="Text"
              dense
              outlined
              :rules="[validation.required, validation.maxLength(255)]"
              class="rounded-0"
            >
            </v-textarea>
          </v-col>
          <v-col cols="12">
            <v-select
              label="Typ"
              dense
              outlined
              item-text="name"
              :rules="[validation.required]"
              v-model="form.type"
              :items="alertTypes"
              class="rounded-0"
            >
            </v-select>
          </v-col>
          <v-col cols="12" class="d-flex justify-end">
            <v-btn
              :loading="savingData"
              :disabled="!formValid"
              color="primary"
              @click="save"
              class="rounded-0">
              Uložit
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-card>
</template>

<script>
export default {
  name: 'AlertForm',
  props: {
    alert: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      savingData: false,
      formValid: true,
      form: {},
      alertTypes: [{
        name: 'Info',
        value: 'info'
      },
      {
        name: 'Upozornění',
        value: 'warning'
      },
      {
        name: 'Výstraha',
        value: 'error'
      }]
    }
  },
  methods: {
    save () {
      this.savingData = true
      const options = {
        method: this.alert.id ? 'PUT' : 'POST',
        url: this.alert.id ? `/admin/alerts/${this.alert.id}` : '/admin/alerts',
        data: { ...this.form }
      }

      this.$http(options)
        .then(() => {
          let successMessage
          if (this.alert.id) {
            successMessage = 'Upozornění vytvořeno'
          } else {
            successMessage = 'Editace proběhla úspěšně'
          }
          this.$store.dispatch('alerts/setAlerts', [{
            type: 'success',
            message: successMessage
          }])
        })
        .finally(() => {
          this.$emit('form-close')
        })
    }
  },
  created () {
    this.form = { ...this.alert }
  }
}
</script>

<style scoped>

</style>
