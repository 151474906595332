<template>
  <v-card>
    <v-card-title>
      Upozornění
      <v-spacer></v-spacer>
      <v-btn
        outlined
        class="rounded-0"
        text
        @click="addAlert"
      >
        Přidat upozornění
      </v-btn>
    </v-card-title>
    <v-data-table
      :loading="loading"
      :items="alerts"
      item-key="id"
      :headers="headers"
      :footer-props="{'items-per-page-options': [10, 20, 50] }"
    >
      <template v-slot:item.active="{ item }">
        {{item.active ? 'Ano' : 'Ne'}}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          @click="editAlert(item)"
          class="mr-2"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          @click="deleteAlert(item)"
          class="mr-2"
        >
          mdi-delete
        </v-icon>
        <v-icon
          small
          class="mr-2"
          @click="toggleActive(item)"
        >
          {{item.active ?  'mdi-sleep': 'mdi-sleep-off'}}
        </v-icon>
      </template>
    </v-data-table>
    <v-dialog
      v-model="alertFormVisible"
    >
      <AlertForm
        v-if="alertFormVisible"
        :alert="editedAlert"
        @form-close="alertFormVisible = false; getAlerts()"
      />
    </v-dialog>
    <v-dialog
      width="350"
      v-model="deleteDialog">
      <DeleteResource
        :resource="alertToDelete"
        resource-type="admin/alerts"
        @delete-success="showDeleteSuccess(); getAlerts();"
        @delete-failure="showDeleteFailure"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import AlertForm from '@/components/settings/alerts/AlertForm'
import DeleteResource from '@/components/DeleteResource'
import archiveHelpers from '@/mixins/local/archiveHelpers'
export default {
  name: 'AlertSettings',
  mixins: [archiveHelpers],
  components: { DeleteResource, AlertForm },
  data () {
    return {
      loading: false,
      alertFormVisible: false,
      editedAlert: {},
      alertToDelete: {},
      defaultAlert: {
        text: null,
        active: false,
        type: null
      },
      headers: [
        { text: 'Text', value: 'text' },
        { text: 'Typ', value: 'typeText' },
        { text: 'Aktivní', value: 'active' },
        {
          text: 'Akce',
          value: 'actions',
          sortable: false
        }
      ],
      alerts: []
    }
  },
  methods: {
    addAlert () {
      this.editedAlert = { ...this.defaultAlert }
      this.alertFormVisible = true
    },
    editAlert (alert) {
      this.editedAlert = { ...alert }
      this.alertFormVisible = true
    },
    deleteAlert (alert) {
      this.alertToDelete = alert
      this.deleteDialog = true
    },
    getAlerts () {
      this.loading = true
      this.$http.get('/admin/alerts')
        .then(res => {
          this.loading = false
          this.alerts = res.data.map(alert => {
            // pridani CZ textu u typu
            if (alert.type === 'info') {
              alert.typeText = 'Info'
            }
            if (alert.type === 'warning') {
              alert.typeText = 'Upozornění'
            }
            if (alert.type === 'error') {
              alert.typeText = 'Výstraha'
            }
            return alert
          })
        })
    },
    toggleActive (alert) {
      this.loading = true
      this.$http.put(`/admin/alerts/${alert.id}`, {
        ...alert,
        active: !alert.active
      })
        .then(res => {
          this.$set(alert, 'active', !alert.active)
        }).finally(() => {
          this.loading = false
        })
    }
  },
  created () {
    this.getAlerts()
  }
}
</script>

<style scoped>

</style>
