<template>
  <v-card>
    <v-card-title>
      Administrátoři
      <v-spacer></v-spacer>
      <v-btn
        outlined
        class="rounded-0"
        text
        @click="adminFormDialog = true; editedAdmin = defaultAdmin"
      >
        Přidat admina
      </v-btn>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="admins"
      :loading="loading"
      :footer-props="{'items-per-page-options': [10, 20, 50] }"
    >
      <template v-slot:item.role="{ item }">
        {{ item.roleId === 1 ? 'Administrátor' : 'Operátor' }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          @click="deleteItem(item)"
          class="mr-2"
        >
          mdi-delete
        </v-icon>
        <v-icon
          class="mr-2"
          small
          @click="adminFormDialog = true; editedAdmin = item"
        >
          mdi-pencil
        </v-icon>
      </template>
    </v-data-table>

    <v-dialog
      v-model="deleteDialog"
    >
      <DeleteResource
        resource-type="admin/admins"
        @delete-failure="showDeleteFailure"
        @delete-success="deleteSuccess"
        :resource="adminToDelete"/>
    </v-dialog>
    <v-dialog v-model="adminFormDialog">
      <AdminForm
        v-if="adminFormDialog"
        :admin="editedAdmin"
        @edit-success="showEditSuccess"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import DeleteResource from '../../DeleteResource'
import archiveHelpers from '../../../mixins/local/archiveHelpers'
import AdminForm from '@/components/settings/admin/AdminForm'
import { mapActions } from 'vuex'

export default {
  name: 'AdminSettings',
  mixins: [archiveHelpers],
  components: {
    AdminForm,
    DeleteResource
  },
  data () {
    return {
      loading: false,
      headers: [
        {
          text: 'Název',
          value: 'nickname'
        },
        {
          text: 'Login',
          value: 'email'
        },
        {
          text: 'Oprávnění',
          value: 'role'
        },
        {
          text: 'Akce',
          value: 'actions',
          sortable: false
        }
      ],
      admins: [],
      adminToDelete: {},
      adminFormDialog: false,
      defaultAdmin: {
        email: null
      },
      editedAdmin: {}
    }
  },
  methods: {
    async getAdmins () {
      this.loading = true
      this.admins = (await this.$http.get('/admin/admins')).data
      this.loading = false
    },
    deleteItem (item) {
      this.adminToDelete = item
      this.deleteDialog = true
    },
    deleteSuccess () {
      this.showDeleteSuccess()
      this.getAdmins()
    },
    showEditSuccess () {
      this.adminFormDialog = false
      this.setAlerts([{
        message: 'Data byla uložena',
        type: 'success'
      }])
      this.getAdmins()
    },
    ...mapActions({
      setAlerts: 'alerts/setAlerts'
    })
  },
  async created () {
    this.getAdmins()
  }
}
</script>

<style scoped>

</style>
