<template>
  <div>
    <v-tabs
      v-model="tab"
      fixed-tabs
      background-color="indigo"
      color="white"
      dark
    >
      <v-tab>Administrátoři</v-tab>
      <v-tab>Upozornění</v-tab>
    </v-tabs>
    <v-tabs-items
      v-model="tab"
    >
      <v-tab-item>
        <AdminSettings></AdminSettings>
      </v-tab-item>
      <v-tab-item>
        <AlertsSettings></AlertsSettings>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import AdminSettings from '../../components/settings/admin/AdminSettings'
import AlertsSettings from '../../components/settings/alerts/AlertsSettings'
export default {
  name: 'Settings',
  components: { AlertsSettings, AdminSettings },
  data () {
    return {
      tab: 0
    }
  }
}
</script>

<style scoped>

</style>
