<template>
  <v-card>
    <v-form v-model="formValid">
      <v-container>
        <v-alert v-if="emailError" type="error">
          E-mail je již používán
        </v-alert>
        <v-row>
          <v-col cols="12">
            <v-text-field
              label="Název"
              dense
              outlined
              v-model="form.nickname"
              :rules="[validation.required]"
              class="rounded-0"
            >
            </v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            label="Email"
            dense
            v-model="form.email"
            outlined
            class="rounded-0"
            :rules="[validation.required, validation.email]"
            type="email">
          </v-text-field>
        </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="form.password"
              :rules="admin.id ? [validation.minLength(6)] : [validation.required, validation.minLength(6)] "
              class="rounded-0"
              dense
              label="Heslo"
              outlined
              required
              type="password">
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="form.passwordConfirmation"
              :rules="!!form.password ? [validation.required, validation.equal(form.password)] : [validation.equal(form.password)]"
              class="rounded-0"
              dense
              label="Heslo znovu"
              outlined
              required
              type="password">
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <v-select
              label="Typ admina"
              dense
              outlined
              v-model="form.roleId"
              :items="adminTypes"
              class="rounded-0"
              :rules="[validation.required]"
            >
            </v-select>
        </v-col>
        <v-col cols="12" class="d-flex justify-end">
          <v-btn
            color="primary"
            :disabled="!formValid || runningRequest"
            class="rounded-0"
            @click="save"
          >
            Uložit
          </v-btn>
        </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-card>
</template>

<script>
export default {
  name: 'AdminForm',
  props: {
    admin: {
      type: Object
    }
  },
  data () {
    return {
      adminTypes: [
        {
          text: 'Administrátor',
          value: 1
        },
        {
          text: 'Operátor',
          value: 2
        }
      ],
      formValid: true,
      form: {
        email: null,
        nickname: null,
        password: null,
        passwordConfirmation: null,
        roleId: 2
      },
      runningRequest: false,
      emailError: false
    }
  },
  methods: {
    save () {
      this.emailError = false
      this.runningRequest = true
      this.$http({
        method: this.admin.id ? 'PUT' : 'POST',
        url: this.admin.id ? `/admin/admins/${this.admin.id}` : '/admin/admins',
        data: this.form
      })
        .then(res => {
          this.$emit('edit-success')
        })
        .catch(err => {
          if (err.response.data.message === 'EMAIL_ALREADY_USED') {
            this.emailError = true
            this.$store.dispatch('alerts/setAlerts', [])
          }
        })
        .finally(() => {
          this.runningRequest = false
        })
    }
  },
  created () {
    this.form = { ...this.admin }
  }
}
</script>

<style scoped>

</style>
